import React, { useContext, useEffect, useState } from "react";
import {
    FormControlLabel,
    FormGroup,
    Modal,
    Switch,
    Typography,
    Button,
    Stack
} from "@mui/material";
import {
    Calendar,
    DateObject,
    getAllDatesInRange,
} from "react-multi-date-picker";
import LoadingButton from '@mui/lab/LoadingButton';
import "react-calendar/dist/Calendar.css";
import "./_index.css";
import french_calendar from "../../../locales/fr/calendar_fr";
import gregorian_en from "react-date-object/locales/gregorian_en";
import { Box } from "@mui/system";
import { ContentContext } from "../layout";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import Loader from "../Loader";
import { Close } from "@mui/icons-material";


const DatePicker = ({ params }: { params: { carName: string, language: string } }) => {

    const selectedCarName = params.carName

    const ln_local = params.language.toLocaleLowerCase();
    const [calendarLang, setCalendarLang] = useState(gregorian_en);
    const { translate } = useContext(ContentContext);

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 350,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        background: "#fff",
        borderRadius: "6px",
    };

    const [selectRange, setSelectRange] = useState<boolean>(false)
    const [selectedDates, setSelectedDates] = useState<string[] | Date[]>([])
    const [datesToBeDisabled, setDatesToBeDisabled] = useState<string[]>([''])
    const [loadDisableDates, setLoadingDisableDates] = useState<boolean>(true);
    const [loaderBtn, setLaderBtn] = useState<boolean>(false);

    const [chooseTwoDatesAndAboveError, setChooseChooseTwoDatesAndAboveError] =
        useState<boolean>(false);
    const [limitNumberOfDays, setLimitNumberOfDays] = useState<boolean>(false);

    const getBookedDays = () => {
        let data = {
            carName: params.carName,
        };
        setLaderBtn(true)

        axios
            .post(`${API_URL}/getBookedDays`, data)
            .then((r) => {
                setDatesToBeDisabled(
                    r.data.flatMap((day: any) =>
                        day[0]?.map((day: any) => day.split("T")[0])
                    )
                );
                setLoadingDisableDates(false);
                setLaderBtn(false)

            })
            .catch((e) => {
                setLaderBtn(false)

            });
    };
    const closeDatePicker = () => {
        console.log("close");
        MessengerExtensions.requestCloseBrowser(function success() {
            console.log("Webview closing");
        }, function error(err:any) {
            console.log({ err });
        });

    }
    const selectDates = () => {

        setLaderBtn(true)

        const data = {
            days: selectedDates,
            startDate: selectedDates[0],
            endDate: selectedDates[selectedDates.length - 1],
            carName: selectedCarName,
            language: params.language
        }

        axios
            .post(`${API_URL}/selectDates`, data)
            .then((res) => {
                console.log({ res: res.data });
                setLaderBtn(false)
                closeDatePicker()

            })
            .catch((e) => {
                console.log({ error: e });
                setLaderBtn(false)

            });
    };

    const handleSelectDateRange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectRange(event.target.checked);
        setSelectedDates([]);
    };

    const getDate = (selectedDates: DateObject | DateObject[] | null) => {
        if (selectedDates) {
            if (selectRange && !(selectedDates instanceof DateObject)) {
                const finale: Array<string> = [];
                const datesList = getAllDatesInRange(selectedDates).map(
                    (e) =>
                        (e instanceof Date ? e : e.toDate()).toISOString().split("T")[0]
                );
                datesList.map((d) => {
                    if (!datesToBeDisabled.includes(d)) {
                        finale.push(d);
                    }
                });
                setSelectedDates(finale.map((d) => new Date(d)));
                return;
            }
            if (selectedDates instanceof DateObject) {
                setSelectedDates([selectedDates.toDate()]);
            } else {
                setSelectedDates([...selectedDates.map((d) => d.toDate())]);
            }
        }
    };

    useEffect(() => {
        setCalendarLang(ln_local === "en" ? gregorian_en : french_calendar);
        getBookedDays();
    }, []);


    useEffect(() => {
        if (selectedDates.length <= 30) {
            setLimitNumberOfDays(false);
        } else if (selectedDates.length > 30) {
            setLimitNumberOfDays(true);
        }
    }, [selectedDates.length]);


    return (
        <>
            {loadDisableDates ? <Loader /> : (
                <div className="container-main" style={{ maxWidth: '350px !important' }}>
                    <br />
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={selectRange}
                                    onChange={handleSelectDateRange}
                                />
                            }
                            label={translate("select_a_range_of_date")}
                        />
                    </FormGroup>
                    <Calendar
                        locale={calendarLang}
                        minDate={new Date()}
                        onChange={getDate}
                        sort
                        value={selectedDates}
                        range={selectRange}
                        multiple
                        className="dark"
                        mapDays={({ date }) => ({
                            disabled: datesToBeDisabled.includes(
                                date.toDate().toISOString().split("T")[0]
                            ),
                        })}
                    />
                    {chooseTwoDatesAndAboveError && (
                        <Typography fontSize="x-small" color={"red"}>
                            {translate("minimum_number_of_reservations_is_two_days")}
                        </Typography>
                    )}
                    <br />
                    <Stack direction={"row"} columnGap={2} alignItems="end">
                        {!loaderBtn ? <Button
                            fullWidth
                            variant="contained"
                            className="primary"
                            type="submit"
                            sx={{
                                height: 50,
                                borderRadius: "6px",
                                textTransform: "initial",
                                fontSize: "20px",
                                "&:disabled": {
                                    "backgroundColor": "grey !important",
                                    "WebkitTextFillColor": "#f2f2f2 !important",
                                },
                                marginTop: "10px",
                                flexGrow: 1,
                            }}
                            disabled={selectedDates.length < 1 || selectedDates.length >= 31}
                            onClick={() => selectDates()}
                        >
                            {
                                translate("next")
                            }
                        </Button> :
                            <LoadingButton
                                fullWidth
                                classes='iconSizeLarge'
                                variant="contained"
                                type="submit"
                                sx={{
                                    backgroundColor: "grey",
                                    height: 50,
                                    borderRadius: "6px",
                                    textTransform: "initial",
                                    fontSize: "50px",
                                    marginTop: "10px",
                                    color: "grey",
                                    flexGrow: 1,
                                }} loading={true} />}
                    </Stack>

                    <Modal
                        open={limitNumberOfDays}
                        onClose={() => {
                            setLimitNumberOfDays(false);
                            setSelectedDates([]);
                        }}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{ ...style, position: "relative" }}>
                            <Close
                                onClick={() => {
                                    setLimitNumberOfDays(false);
                                    setSelectedDates([]);
                                }}
                                sx={{ position: "absolute", right: "10px", top: "15px" }}
                            />
                            <Typography
                                color="orange"
                                id="modal-modal-description"
                                sx={{ mt: 2 }}
                            >
                                {translate("limit_number_of_days")}
                            </Typography>
                        </Box>
                    </Modal>
                </div>
            )}
        </>
    );
};

export default DatePicker;
