import React, { useEffect } from "react";
import { HeadFC } from 'gatsby'
import DatePicker from '../../../components/DatePicker/index';
import Layout from '../../../components/layout/index';
import queryString from "query-string";

const datePickerPage = () => {

  const location = typeof window !== "undefined" ? window.location.search : null
  const { carName, language } = queryString.parse(location as string);
  console.log({ carName, language });
  const params = { carName: carName as string, language: language as string }

  useEffect(() => {
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.com/en_US/messenger.Extensions.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'Messenger'));

    window.extAsyncInit = function () {
      // the Messenger Extensions JS SDK is done loading 
      MessengerExtensions.getUserID(function success(uids) {
        var psid = uids.psid;
        console.log({ psid });


      }, function error(err) {
        console.error(err);

      });
    };

    return () => {

    }
  }, [])

  return (<Layout><DatePicker params={params} /></Layout>)
}
export const Head: HeadFC = () => <title>DatePicker</title>

export default datePickerPage;
